<!--门禁模块--当次日时间表标题-->
<template>
  <div class="templatetitle">
    <div class="templatetitle-left">
      <span>{{ name }}</span>
      <div>{{ $t("details.a59") }}</div>
    </div>
    <ul class="templatetitle-right">
      <li>
        <div class="upright" style="background: #0fce0f"></div>
        {{ $t("details.a60") }}
      </li>
      <li>
        <div class="upright" style="background: #f8b62d"></div>
        {{ $t("details.a61") }}
      </li>
      <li>
        <div class="upright" style="background: #eeee00"></div>
        {{ $t("details.a62") }}
      </li>
      <li>
        <div class="upright" style="background: #7682ce"></div>
        {{ $t("details.a63") }}
      </li>
      <li>
        <div class="upright" style="background: #00a0e9"></div>
        {{ $t("details.a64") }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
  },
};
</script>

<style scoped>
.templatetitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.templatetitle-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.templatetitle-left::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 18px;
  background-color: #7682ce;
  margin-right: 10px;
}
.templatetitle span {
  font-size: 18px;
  margin-right: 10px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
</style>