<!--门禁模块--手动时间表恢复组件-->
<template>
  <div id="select-assign" v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
    <!-- <my-title :title="title"></my-title> -->
    <div class="title">
      <span>{{ $t("doorDetails.a99") }}</span>
    </div>
    <div class="select-assign-main">
      <div>
        <span>{{ $t("doorDetails.a50") }}</span>
      </div>
      <div>
        <a-checkbox v-model="door"> {{ $t("doorDetails.a51") }} </a-checkbox>
        <a-checkbox v-model="card"> {{ $t("doorDetails.a52") }} </a-checkbox>
        <a-checkbox v-model="sec"> {{ $t("doorDetails.a53") }} </a-checkbox>
      </div>
    </div>
    <div class="select-assign-floor">
      <div>
        <a-button type="primary" style="width: 120px" ghost @click="setTimetable" :loading="loading">{{ $t("doorDetails.a12") }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import tabletitle from '../../../components/Title/tabletitle'
import { ManualRecoverySchedule } from '../../../api/door'

export default {
  props: {
    id: {
      type: Number
    },
    type: {
      type: Number
    }
  },
  data() {
    return {
      title: this.$t('doorDetails.a99'),
      loading: false,
      door: false,
      card: false,
      sec: false
    }
  },
  computed: {
    doorChk: function () {
      if (this.door == true) {
        return "1";
      } else {
        return "0";
      }
    },
    cardChk: function () {
      if (this.card == true) {
        return "1";
      } else {
        return "0";
      }
    },
    secChk: function () {
      if (this.sec == true) {
        return "1";
      } else {
        return "0";
      }
    },
  },
  methods: {
    // 手动恢复时间表的方法
    setTimetable() {
      this.$confirm({
        title: this.$t('doorDetails.a99'),
        centered: true,
        onOk: () => {
          this.loading = true
          let idAndTypes = [this.id.toString(), this.type.toString()]
          let data = new FormData()
          data.append('doorChk', this.doorChk)
          data.append('cardChk', this.cardChk)
          data.append('secChk', this.secChk)
          data.append('idAndTypes', idAndTypes)
          ManualRecoverySchedule(data)
            .then((res) => {
              // console.log(res)
              this.loading = false
              if (res.errorCode == '00') {
                this.$message.success(res.msg)
              } else if (res.errorCode == '02') {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    }
  },
  components: {
    "my-title": tabletitle
  }
}
</script>

<style scoped>
#select-assign {
  border: 1px solid #e8e9ea;
  margin-bottom: 20px;
  border-bottom: none;
  white-space: nowrap;
  border-radius: 3px;
  overflow: hidden;
}
.title {
  font-size: 18px;
  background-color: #f2f4f7;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e9ea;
}
.select-assign-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid #e8e9ea;
}
.select-assign-floor{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid #e8e9ea;
}
</style>