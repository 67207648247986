<!-- 当次日时间表 -->
<template>
  <div id="dailySchedule" v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
    <div class="vessel">
      <!-- 当日时间表 -->
      <div class="vessel-left">
        <!-- 当日时间表 -->
          <div class="title">
            <div>{{ $t('doorDetails.a95') }}</div>
          </div>
          <div class="main">
              <div>
                <!-- 模型1 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a54') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule1.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule1.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule1.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule1.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <!--  -->
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule1.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型2 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a55') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule2.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule2.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule2.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule2.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule2.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型3 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a56') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule3.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule3.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule3.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule3.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule3.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型4 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a57') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule4.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule4.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule4.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule4.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule4.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型5 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a58') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule5.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule5.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule5.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule5.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleToday.schedule2.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
              </div>
              <div>
                <my-pie
                  :data="scheduleToday"
                  id="scheduleTodayPie"
                  :name="$t('doorDetails.a96')"
                  :day="moment().format('YYYY/MM/DD')"
                />
              </div>
          </div>
      </div>
      <!-- 次日时间表 -->
      <div class="vessel-right">
          <div class="title"> 
            <div>{{ $t('doorDetails.a97') }}</div>
          </div>
          <div class="main">
              <div>
                <!-- 模型1 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a54') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule1.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule1.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule1.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule1.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule1.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型2 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a55') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule2.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule2.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule2.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule2.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule2.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型3 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a56') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule3.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule3.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule3.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule3.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule3.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型4 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a57') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule4.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule4.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule4.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule4.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule4.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
                <!-- 模型5 -->
                <a-row type="flex" justify="space-around" align="middle" class="column">
                  <a-col>
                    <span class="name">{{ $t('doorDetails.a58') }}</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule5.begin[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule5.begin[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col>
                    <span class="interim">~</span>
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule5.end[0]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in hour" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a102') }}
                  </a-col>
                  <a-col :span="3">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule5.end[1]" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in minute" :key="item">{{ item }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col>
                    {{ $t('doorDetails.a103') }}
                  </a-col>
                  <a-col :span="5">
                    <a-select style="width: 100%" v-model="scheduleTomorrow.schedule5.model" :getPopupContainer="triggerNode => triggerNode.parentNode">
                      <a-select-option v-for="item in patterns" :key="item.value">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
              </div>
              <div class="piece">
                <my-pie
                  :data="scheduleTomorrow"
                  id="scheduleTomorrow"
                  :name="$t('doorDetails.a98')"
                  :day="moment().add(1, 'days').format('YYYY/MM/DD')"
                />
              </div>
          </div>
      </div>
    </div>
    <!-- 底栏 -->
    <div class="floor">
      <div>
        <ul class="templatetitle-right">
          <li>
            <div class="upright" style="background: #0fce0f"></div>
            {{ $t("doorDetails.a60") }}
          </li>
          <li>
            <div class="upright" style="background: #f8b62d"></div>
            {{ $t("doorDetails.a61") }}
          </li>
          <li>
            <div class="upright" style="background: #eeee00"></div>
            {{ $t("doorDetails.a62") }}
          </li>
          <li>
            <div class="upright" style="background: #7682ce"></div>
            {{ $t("doorDetails.a63") }}
          </li>
          <li>
            <div class="upright" style="background: #00a0e9"></div>
            {{ $t("doorDetails.a64") }}
          </li>
        </ul>
    </div>
      <a-button style="width:120px" type="primary" ghost @click="timeSet">
        {{ $t('doorDetails.a12') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import pin from "./pie"
import moment from "moment"
import { updateScheduleTable } from "../../../api/door"

export default {
  props:{
    data:{
      type:Object
    }
  },
  components: {
    "my-pie": pin
  },
  data(){
    return{
      hour:['--','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
      minute:['--','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
      scheduleToday:{
        schedule1:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule2:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule3:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule4:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule5:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        }
      },
      scheduleTomorrow:{
        schedule1:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule2:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule3:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule4:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        },
        schedule5:{
          begin:['--','--'],
          end:['--','--'],
          model:0
        }
      },
      patterns:[],
      loading:false
    }
  },
  watch:{
    // 当次日时间表数据操作
    data:{
      handler(newValue){
        this.patterns = newValue.scheduleToday.patterns
        const _data = JSON.stringify(newValue)
        const data = JSON.parse(_data)
        const scheduleToday = data.scheduleToday
        const scheduleTomorrow = data.scheduleTomorrow
        delete scheduleToday.patterns
        delete scheduleTomorrow.patterns
        for(let i in scheduleToday){
          if(scheduleToday[i].begin == ''){
            scheduleToday[i].begin = ['--','--']
            scheduleToday[i].end = ['--','--']
          }else{
            scheduleToday[i].begin = scheduleToday[i].begin.split(':')
            scheduleToday[i].end = scheduleToday[i].end.split(':')
          }
        }
        for(let i in scheduleTomorrow){
          if(scheduleTomorrow[i].begin == ''){
            scheduleTomorrow[i].begin = ['--','--']
            scheduleTomorrow[i].end = ['--','--']
          }else{
            scheduleTomorrow[i].begin = scheduleTomorrow[i].begin.split(':')
            scheduleTomorrow[i].end = scheduleTomorrow[i].end.split(':')
          }
        }
        this.scheduleToday = scheduleToday
        this.scheduleTomorrow = scheduleTomorrow
      },
      deep:true,
      immediate:true
    }
  },
    methods:{
      moment,
      // 当次日时间表设置
      timeSet(){
        const scheduleToday = this.scheduleToday
        const scheduleTomorrow = this.scheduleTomorrow
        const time1 = this.timeDetection(scheduleToday)
        const time2 = this.timeDetection(scheduleTomorrow)
        if(time1 && time2){
            this.$confirm({
            title: this.$t('doorDetails.a30'),
            centered: true,
            onOk: () => {
              this.loading = true
              const data = this.schedule()
              updateScheduleTable(data)
              .then(res => {
                this.loading = false
                const { errorCode,msg } = res
                if(errorCode == '00'){
                  this.$message.success(msg)
                }else{
                  this.$message.error(msg)
                }
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
            }
          })
        }else{
            this.$error({
            title: this.$t('doorDetails.a122'),
            centered: true
          })
        }
      },
      // 时间检测
      timeDetection(value){
        let affirm = true
        const _data = JSON.stringify(value)
        const data = JSON.parse(_data)
        let arr = []; // 大数组
        // 将所有时间段做成数组pushu到arr数组
        for (let i in data) {
          let time = []
          const _begin1 = data[i].begin[0] * 60
          const _begin2 = parseInt(data[i].begin[1])
          const _end1 = data[i].end[0] * 60
          const _end2 = parseInt(data[i].end[1])
          console.log(!isNaN(_begin1),_begin2,_end1,_end2)
          if(!isNaN(_begin1) || !isNaN(_begin2)){
            if(isNaN(_begin1) || isNaN(_begin2)){
              affirm = false
            }else{
              time.push(_begin1 + _begin2)
            }
          }
          if(!isNaN(_end1) || !isNaN(_end2)){
            if(isNaN(_end1) || isNaN(_end2)){
              affirm = false
            }else{
              time.push(_end1 + _end2)
            }
          }
          arr.push(time)
        }
        // 以下方法判断每个时间段是否都填写开始时间和结束时间，否affirm = false
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].length == 1) {
            affirm = false;
          } else if (arr[i].length == 2) {
            if (arr[i][0] >= arr[i][1]) {
              affirm = false;
            }
          }
        }
        // // 以下方法判断每个时间段是否存在交叉（重复）的情况，是affirm = false
        for (let i = 0; i < arr.length; i++) {
          let a = arr[i][0];
          for (let j = 0; j < arr.length; j++) {
            if (i !== j) {
              if (a > arr[j][0] && a < arr[j][1]) {
                affirm = false;
              }
            }
          }
        }
        return affirm
      },
      // 时间表
      schedule(){
        const _scheduleToday = JSON.stringify(this.scheduleToday) 
        const scheduleToday = JSON.parse(_scheduleToday)
        const _scheduleTomorrow = JSON.stringify(this.scheduleTomorrow)
        const scheduleTomorrow = JSON.parse(_scheduleTomorrow)
        const id = this.data.id
        const type = this.data.type
        const scheduleData = []
        for (let i in scheduleToday) {
          const begin1 = scheduleToday[i].begin[0]
          const begin2 = scheduleToday[i].begin[1]
          const end1 = scheduleToday[i].end[0]
          const end2 = scheduleToday[i].end[1]
          if(begin1 == '--' || begin2 == '--'){
            scheduleToday[i].begin = ''
          }else{
            scheduleToday[i].begin = begin1 + ':' + begin2
          }
          if(end1 == '--' || end2 == '--'){
            scheduleToday[i].end = ''
          }else{
            scheduleToday[i].end = end1 + ':' + end2
          }
        }
        for (let i in scheduleTomorrow) {
          const begin1 = scheduleTomorrow[i].begin[0]
          const begin2 = scheduleTomorrow[i].begin[1]
          const end1 = scheduleTomorrow[i].end[0]
          const end2 = scheduleTomorrow[i].end[1]
          if(begin1 == '--' || begin2 == '--'){
            scheduleTomorrow[i].begin = ''
          }else{
            scheduleTomorrow[i].begin = begin1 + ':' + begin2
          }
          if(end1 == '--' || end2 == '--'){
            scheduleTomorrow[i].end = ''
          }else{
            scheduleTomorrow[i].end = end1 + ':' + end2
          }
        }
        scheduleToday.id = id
        scheduleToday.type = type
        scheduleTomorrow.id = id
        scheduleTomorrow.type = type
        scheduleData.push(scheduleToday)
        scheduleData.push(scheduleTomorrow)
        return scheduleData
      }
    }
}
</script>

<style scoped>
#dailySchedule{
  width: 100%;
  border: 1px solid #e0e1e2;
  border-radius: 3px;
  border-top: none;
  overflow: hidden;
}
.vessel{
  display: flex;
}
.vessel-left{
  width: 50%;
}
.vessel-right{
  width: 50%;
  border-left: 1px solid #e0e1e2;
}
.column{
  margin-bottom: 15px;
}
.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  font-size: 18px;
  padding: 0px 20px;
  background-color: #f2f4f7;
  border-bottom: 1px solid #e0e1e2;
  border-top: 1px solid #e0e1e2;
}
.main{
  display: flex;
  flex-direction:column;
  padding: 20px;
  font-size: 14px;
}
.name{
  font-size: 14px;
  margin-right: 10px;
}
.interim{
  font-size: 20px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.floor{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0px 20px;
  border-top: 1px solid #e0e1e2;
}
</style>