<!--门禁模块--时间表恢复组件-->
<template>
  <div id="timetableResumption" v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
    <div class="row-title">
      <span>{{ $t("doorDetails.a78") }}</span>
    </div>
    <div class="row-content">
      <div>
        <span>{{ $t("doorDetails.a79") }}</span>
      </div>
      <div>
        <a-radio-group name="radioGroup" v-model="data.auto">
          <a-radio :value="1">{{ $t("doorDetails.a80") }}</a-radio>
          <a-radio :value="0">{{ $t("doorDetails.a81") }}</a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="row-content">
      <div>
        <span>{{ $t("doorDetails.a82") }}</span>
      </div>
      <div>
        <a-time-picker v-model="data.time1" format="HH:mm" :disabled="disabled"/>
      </div>
    </div>
    <div class="row-content">
      <div>
        <span>{{ $t("doorDetails.a83") }}</span>
      </div>
      <div>
        <a-time-picker v-model="data.time2" format="HH:mm" :disabled="disabled"/>
      </div>
    </div>
    <div class="row-content">
      <div>
        <span>{{ $t("doorDetails.a84") }}</span>
      </div>
      <div>
        <a-time-picker v-model="data.time3" format="HH:mm" :disabled="disabled"/>
      </div>
    </div>
    <div class="row-content">
      <div>
        <span>{{ $t("doorDetails.a85") }}</span>
      </div>
      <div>
        <a-time-picker v-model="data.time4" format="HH:mm" :disabled="disabled"/>
      </div>
    </div>
    <div class="row-content">
      <div>
        <span>{{ $t("doorDetails.a86") }}</span>
      </div>
      <div>
        <a-time-picker v-model="data.time5" format="HH:mm" :disabled="disabled"/>
      </div>
    </div>
    <div class="row-footer">
      <a-button type="primary" style="width: 120px" ghost @click="setTimetable" :loading="loading">{{ $t("doorDetails.a12") }}</a-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { setTimetableResumption } from '../../../api/door'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: Number
    },
    id: {
      type: Number
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    // 根据周期自动复原的值禁用选择框
    disabled: function () {
      if (this.data.auto == 0) {
        return true;
      } else if (this.data.auto == 1) {
        return false;
      } else {
        return false;
      }
    },
  },
  methods: {
    moment,
    // 修改时间表恢复组件设定值的方法
    setTimetable() {
      this.$confirm({
        title: this.$t('doorDetails.a30'),
        centered: true,
        onOk: () => {
          this.loading = true;
          let data = new FormData();
          data.append('id', this.id);
          data.append('type', this.type);
          data.append('valid', this.disabled);
          data.append('recoverTime1', this.data.time1.minute() + this.data.time1.hour() * 60)
          data.append('recoverTime2',this.data.time2.minute() + this.data.time2.hour() * 60)
          data.append('recoverTime3',this.data.time3.minute() + this.data.time3.hour() * 60)
          data.append('recoverTime4',this.data.time4.minute() + this.data.time4.hour() * 60)
          data.append('recoverTime5',this.data.time5.minute() + this.data.time5.hour() * 60)
          setTimetableResumption(data)
            .then((res) => {
              // console.log(res);
              this.loading = false
              if (res.errorCode == '00') {
                this.$message.success(res.msg)
              } else if (res.errorCode == '02') {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    }
  }
}
</script>
 
<style scoped>
#timetableResumption {
  width: 100%;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
}
.row-title {
  font-size: 18px;
  background-color: #f2f4f7;
  padding: 10px 20px;
  border: 1px solid #e8e9ea;
}
.row-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e9ea;
  border-top: none;
}
.row-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e9ea;
  border-top: none;
}
input {
  width: 120px;
  margin-right: 10px;
}
</style>